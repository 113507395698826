import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = () => (
    <Layout>
        <Seo title="Privacy"></Seo>
        <h1 className="mt-24">Privacy Policy.</h1>
        <p className="text-lg font-bold">Informativa ai sensi del D.Lgs. n. 101/2018 sulla protezione delle persone fisiche con riguardo al
            trattamento dei dati personali, ss.mm.ii. e del Regolamento UE 679/2016 - Regolamento Generale sulla
            Protezione dei Dati (“RGPD”).</p>
        <h3>1. Titolare del trattamento</h3>
        <p>Titolare del trattamento è l’Ambulatorio Veterinario Associato Loni, Marcucci, Lami, con sede in via Cisanello 163,
            Pisa (PI), P.IVA n. 01878220506 . (di seguito definito l’“Ambulatorio”). Per contattare il Titolare in materia di privacy è
            possibile scrivere a: info@ambulatorioveterinariopisa.it. L’elenco aggiornato dei Responsabili e degli Autorizzati al
            trattamento è nella sede sopra citata.</p>
        <h3>2. Dati personali oggetto del trattamento</h3>
        <p>Per poter rispondere alla Sua richiesta di contatto o assistenza e consulenza, utilizziamo i Suoi dati identificativi e
            l’indirizzo mail che ha rilasciato.</p>
        <h3>3. Finalità, base giuridica e natura del trattamento.</h3>
        <p>La raccolta e il trattamento dei Suoi dati personali comuni sono effettuati esclusivamente per:
            <ul>
                <li>poter rispondere alla Sua richiesta;</li>
                <li>interesse legittimo del Titolare;</li>
                <li>l'assolvimento di obblighi previsti da leggi dello Stato, regolamenti e normative comunitarie (ad es. normativa
                    antiriciclaggio), da disposizioni impartite da autorità a ciò legittimate dalla legge e da organi di controllo o di
                    vigilanza.</li>
            </ul>
            Il conferimento di suddetti dati ha natura obbligatoria: diversamente non saremmo in grado di adempiere, totalmente
            o parzialmente, alla richiesta ricevuta. Il relativo trattamento non richiede il consenso dell’interessato.
        </p>
        <p>I Dati da Lei forniti, saranno trattati attenendosi ai principi di correttezza, liceità, pertinenza, proporzionalità e
            trasparenza.
            Il Titolare non utilizza processi decisionali automatizzati che riguardano i Suoi dati personali.</p>
            <h3>4. Destinatari</h3>
            <p>I Suoi dati personali comuni saranno trattati in forma cartacea, informatica o telematica dal personale interno e dai
collaboratori esterni dell’Ambulatorio autorizzati al trattamento. L’elenco è disponibile presso la sede del Titolare.
I dati potranno essere raccolti anche presso altri soggetti con cui l’Ambulatorio collabora per le sue attività.
I dati potrebbero inoltre essere comunicati a nostri fornitori di servizi quali, società di servizi informatici, società di
outsourcing, consulenti e liberi professionisti, da noi nominati Responsabili dello specifico trattamento ed il cui elenco
è a Sua disposizione presso la sede del Titolare.
I Suoi dati saranno comunicati alle Autorità ed Enti pubblici nei casi previsti dalle leggi.</p>
<h3>5. Tasferimento dati</h3>
<p>Alcuni dei Suoi dai personali comuni potrebbero essere trasferiti a soggetti di un Paese terzo posto al di fuori dello
Spazio Economico Europeo. Il Titolare assicura che il trattamento di tali dati avviene nel rispetto del GDPR e della
normativa applicabile.</p>
<h3>6. Conservazione dei dati.</h3>
<p>I dati personali saranno conservati fino alla cessazione dell’attività del Titolare e nel rispetto degli obblighi di legge.
I dati personali dei quali non è necessaria la conservazione in relazione agli scopi indicati, saranno cancellati o
trasformati in forma anonima. I sistemi informativi impiegati per la gestione delle informazioni raccolte sono
configurati, già in origine, in modo da minimizzare l&#39;utilizzo degli stessi.</p>
<h3>7. Suoi Diritti.</h3>
<p>In qualità di interessato, può esercitare i diritti di cui all’art. 15 GDPR secondo cui:
    <ul>
        <li>può ottenere la conferma dell&#39;esistenza o meno di dati personali che La riguardano, anche se non ancora
registrati, e la loro comunicazione in forma intelligibile;</li>
        <li>può ottenere l&#39;indicazione: a) dell&#39;origine dei dati personali; b) delle finalità e modalità del trattamento; c)
della logica applicata in caso di trattamento effettuato con l&#39;ausilio di strumenti elettronici; d) degli estremi
identificativi del Titolare, dei Responsabili e del rappresentante designato ai sensi dell&#39;art. 3, comma 1, GDPR;
e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono
venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o
incaricati;</li>
        <li>può ottenere: a) l&#39;aggiornamento, la rettificazione ovvero, quando vi ha interesse, l&#39;integrazione dei dati; b) la
cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi
quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o
successivamente trattati; c) l&#39;attestazione che le operazioni di cui alle lettere a) e b) sono state portate a
conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o
diffusi, eccettuato il caso in cui tale adempimento si riveli impossibile o comporti un impiego di mezzi
manifestamente sproporzionato rispetto al diritto tutelato;</li>
        <li>può opporsi, in tutto o in parte: per motivi legittimi al trattamento dei dati personali che La riguardano,
ancorché pertinenti allo scopo della raccolta.</li>
    </ul>
    Ove applicabili, ha altresì i diritti di cui agli artt. 16-21 GDPR (Diritto di rettifica, diritto all’oblio, diritto di limitazione di
trattamento, diritto alla portabilità dei dati, diritto di opposizione), nonché il diritto di reclamo all’Autorità Garante.
</p>
<p className="text-ms">Settembre 2022</p>
    </Layout>
)

export default Privacy